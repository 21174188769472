
import { defineComponent } from 'vue';
import { PublicUser } from '@/interfaces/social/publicUser';

export default defineComponent({
    name: 'PublicUserBadge',
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    computed: {
        typedUser(): PublicUser {
            return this.user as PublicUser;
        },
        userAvatarStyle(): Record<string, string> {
            return {
                backgroundImage: `url(${this.user.avatarUri})`,
            };
        },
    },
});
